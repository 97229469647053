"use client";
import Duck from "@/components/common/3D/Duck";
import Scene from "@/components/common/3D/Scene";
import { MagneticChipButton } from "@/components/common/Buttons/MagneticChipButton/MagneticChipButton";
import { BreakPointProps, useResponsive3D } from "@/utils/useResponsive3D";
import { PerspectiveCamera } from "@react-three/drei";
import { useTranslations } from "next-intl";
import { degToRad } from "three/src/math/MathUtils";
import style from "./not-found.module.scss";

export default function NotFound() {
  const translate = useTranslations("not-found");
  const breakPoints: BreakPointProps[] = [
    {
      breakpoint: [300, 1000],
      props: {
        position: [-4.75, 3, 0],
        rotation: [0, -20, 0],
        duckRotation: -50,
        sacale: 4.5,
      },
    },
    {
      breakpoint: [1001, 1200],
      props: {
        position: [-1, 3.4, 2],
        rotation: [0, 0, 0],
        duckRotation: -45,
        sacale: 5,
      },
    },
    {
      breakpoint: [1201, 1600],
      props: {
        position: [-1.5, 3, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
        sacale: 5.5,
      },
    },
    {
      breakpoint: [1601, 1900],
      props: {
        position: [-3.5, 2.5, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
        sacale: 6,
      },
    },
    {
      breakpoint: [1901, 2500],
      props: {
        position: [-3.5, 2.5, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
        sacale: 6,
      },
    },
  ];

  const { breakProps } = useResponsive3D({
    breakPoints: breakPoints,
  });
  return (
    <section className={`container ${style.wrapper}`}>
      <div>
        <h1>{translate("title")}</h1>

        <MagneticChipButton
          label={translate("button")}
          variant="outline"
          onClick={() => null}
          href={`/`}
        />
        <div>
          <Scene
            style={{
              width: "100vw",
              height: "100vh",
            }}
          >
            <PerspectiveCamera
              makeDefault
              position={[
                breakProps?.position[0],
                breakProps?.position[1] + 0.5,
                breakProps?.position[2] + 17,
              ]}
              rotation={[
                degToRad(breakProps?.rotation[0] - 5),
                degToRad(breakProps?.rotation[1] - 5),
                degToRad(breakProps?.rotation[2]),
              ]}
              fov={30}
            />
            <Duck
              scale={breakProps?.sacale}
              position={[3.25, -1.99, 0]}
              rotation={[0, degToRad(breakProps?.duckRotation), 0]}
              cursorAnimations
              cursorAngleLimit={20}
            ></Duck>
          </Scene>
        </div>
      </div>
    </section>
  );
}
