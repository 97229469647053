"use client";
import { Canvas, invalidate } from "@react-three/fiber";
import {
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
  CSSProperties,
} from "react";
import { AdaptivePixelRatio } from "./AdaptivePixelRatio";

interface SceneProps extends PropsWithChildren {
  className?: string;
  parentStyle?: boolean;
  style?: CSSProperties;
  persist?: boolean;
  customEvent?: (boolean) => void;
}

const Scene = ({
  children,
  className,
  style,
  parentStyle = true,
  persist = false,
  customEvent = null,
}: SceneProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const containerRef = useRef();
  const canvasRef = useRef();

  const parentStyleNode: CSSProperties = parentStyle
    ? {
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: "0",
        left: "0",
        pointerEvents: "none",
      }
    : {};

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          invalidate();
        }

        if (customEvent !== null) customEvent(entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className={className} ref={containerRef} style={parentStyleNode}>
      <Canvas
        gl={{
          stencil: false,
          autoClear: false,
          alpha: false,
          antialias: true,
          precision: "highp",
          powerPreference: "high-performance",
        }}
        ref={canvasRef}
        style={style}
        camera={{ position: [0, 0, 20], fov: 50 }}
        frameloop={isVisible && isRendered ? "always" : "demand"}
        onCreated={() => {
          setIsRendered(true);
        }}
      >
        <AdaptivePixelRatio />
        {(isVisible || !isRendered || persist) && children}
      </Canvas>
    </div>
  );
};

export default Scene;
